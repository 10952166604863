// import RegisterStepper from 'Components/Register/Stepper';
import {
  Button,
  TextInput,
  HelperText,
  Checkbox,
  Text,
} from "react-native-paper";
import { TextInputMask } from "react-native-masked-text";
import { Controller } from "react-hook-form";
import { Platform, View } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import moment from "moment";
import DropDown from "react-native-paper-dropdown";
import openUrl from "Util/openUrl";
import environment from "environment";

export default ({
  theme,
  control,
  inputs,
  errors,
  currentUser,
  cognitoUser,
  isValid,
  isSubmitting,
  onSubmit,
  onNext,
  setSecureTextEntry,
  secureTextEntry,
  readOnly,
  showDropDown,
  setShowDropDown,
  country,
  ScrollElement,
  view,
  styles,
}) => (
  <>
    <ScrollElement
      contentContainerStyle={{
        marginHorizontal: theme.spacing.xl,
      }}
      keyboardShouldPersistTaps="handled"
    >
      {!readOnly && view !== "MOBILE_APPROVAL" && (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                disabled
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["email"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "email"
                }
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Email"
                error={!!errors.email}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("firstName")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            </>
          )}
          name="email"
          defaultValue={currentUser?.email}
        />
      )}

      {!readOnly && view !== "MOBILE_APPROVAL" && (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus={!readOnly}
                editable={!readOnly}
                autoCorrect={false}
                ref={e => (inputs.current["displayName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "username"
                }
                textContentType="nickname"
                label="Display Name"
                autoCapitalize="none"
                returnKeyLabel="Next"
                returnKeyType="next"
                error={!!errors.displayName}
                onSubmitEditing={onNext("firstName")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange((value ?? "").toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.displayName}>
                {errors.displayName?.message}
              </HelperText>
            </>
          )}
          name="displayName"
          defaultValue={currentUser?.displayName}
        />
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              disabled={currentUser.verifiedAt}
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="words"
              ref={e => (inputs.current["firstName"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "name"}
              keyboardType="default"
              textContentType="givenName"
              label="First Name"
              error={!!errors.firstName}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("lastName")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.firstName}>
              {errors.firstName?.message}
            </HelperText>
          </>
        )}
        name="firstName"
        defaultValue={cognitoUser.attributes.given_name}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              disabled={currentUser.verifiedAt}
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="words"
              ref={e => (inputs.current["lastName"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "name"}
              keyboardType="default"
              textContentType="familyName"
              label="Last Name"
              error={!!errors.lastName}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("lastName")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.lastName}>
              {errors.lastName?.message}
            </HelperText>
          </>
        )}
        name="lastName"
        defaultValue={cognitoUser.attributes.family_name}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="none"
              ref={e => (inputs.current["mobileNumber"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "tel"}
              keyboardType="phone-pad"
              textContentType="telephoneNumber"
              label="Mobile Number"
              error={!!errors.mobileNumber}
              returnKeyLabel="Done"
              returnKeyType="done"
              onSubmitEditing={onNext("birthDate")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value =>
                onChange(`+${(value ?? "").replace(/\D/g, "")}`)
              }
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
              render={props => (
                <TextInputMask
                  {...props}
                  type="custom"
                  options={{
                    mask: "+1 (999)999-9999",
                  }}
                />
              )}
            />
            <HelperText type="error" visible={!!errors.mobileNumber}>
              {errors.mobileNumber?.message}
            </HelperText>
          </>
        )}
        name="mobileNumber"
        defaultValue={cognitoUser.attributes["custom:mobile"]}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize="none"
              ref={e => (inputs.current["birthDate"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="number-pad"
              textContentType="none"
              editable={!readOnly}
              label="Date of Birth"
              error={!!errors.birthDate}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("address")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
              render={props => (
                <TextInputMask
                  {...props}
                  type="datetime"
                  options={{
                    format: "MM/DD/YYYY",
                  }}
                />
              )}
            />
            <HelperText type="error" visible={!!errors.birthDate}>
              {errors.birthDate?.message}
            </HelperText>
          </>
        )}
        name="birthDate"
        defaultValue={moment(
          cognitoUser.attributes.birthdate,
          /-/.test(cognitoUser.attributes.birthdate)
            ? "YYYY-DD-MM"
            : "MM/DD/YYYY"
        ).format("MM/DD/YYYY")}
      />

      {view !== "MOBILE_APPROVAL" && (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <DropDown
                inputProps={{
                  editable: !readOnly,
                  style: {
                    backgroundColor: "transparent",
                  },
                }}
                dropDownStyle={{
                  borderColor: "pink",
                  borderWidth: 2,
                }}
                label="Country"
                mode="flat"
                visible={!readOnly && showDropDown}
                showDropDown={() => setShowDropDown(true)}
                onDismiss={() => setShowDropDown(false)}
                value={value}
                setValue={onChange}
                list={[
                  { label: "United States", value: "US" },
                  { label: "Canada", value: "CA" },
                ]}
              />
              <HelperText type="error" visible={!!errors.country}>
                {errors.country?.message}
              </HelperText>
            </>
          )}
          name="country"
          defaultValue={cognitoUser.attributes["custom:country"]}
        />
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="words"
              ref={e => (inputs.current["address"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "street-address"
              }
              keyboardType="default"
              textContentType="streetAddressLine1"
              label="Address"
              error={!!errors.address}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("address2")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.address}>
              {errors.address?.message}
            </HelperText>
          </>
        )}
        name="address"
        defaultValue={cognitoUser.attributes.address}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="words"
              ref={e => (inputs.current["address2"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "street-address"
              }
              keyboardType="default"
              textContentType="streetAddressLine2"
              label="Address 2"
              error={!!errors.address2}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("city")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.address2}>
              {errors.address2?.message}
            </HelperText>
          </>
        )}
        name="address2"
        defaultValue={cognitoUser.attributes["custom:address2"]}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCapitalize="words"
              ref={e => (inputs.current["city"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="default"
              textContentType="addressCity"
              label="City"
              error={!!errors.city}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("state")}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.city}>
              {errors.city?.message}
            </HelperText>
          </>
        )}
        name="city"
        defaultValue={cognitoUser.attributes["custom:city"]}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCorrect
              autoCapitalize="words"
              ref={e => (inputs.current["state"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="default"
              textContentType="addressState"
              label={
                view === "MOBILE_APPROVAL"
                  ? "State/Province (2 letter abbreviation)"
                  : country === "CA"
                  ? "Province (2 letter abbreviation)"
                  : "State (2 letter abbreviation)"
              }
              error={!!errors.state}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={onNext("zip")}
              maxLength={2}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value.toUpperCase())}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.state}>
              {errors.state?.message}
            </HelperText>
          </>
        )}
        name="state"
        defaultValue={cognitoUser.attributes["custom:state"]}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              editable={!readOnly}
              autoCorrect={false}
              autoCapitalize="none"
              ref={e => (inputs.current["zip"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "postal-code"
              }
              keyboardType="default"
              textContentType="postalCode"
              label={
                view === "MOBILE_APPROVAL"
                  ? "Zip/Postal Code"
                  : country === "CA"
                  ? "Postal Code"
                  : "Zip"
              }
              error={!!errors.zip}
              returnKeyLabel="Done"
              returnKeyType="done"
              onSubmitEditing={onNext("password")}
              maxLength={country === "CA" ? 6 : 5}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.zip}>
              {errors.zip?.message}
            </HelperText>
          </>
        )}
        name="zip"
        defaultValue={cognitoUser.attributes["custom:zip"]}
      />

      {!readOnly && view !== "MOBILE_APPROVAL" && (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                editable={!readOnly}
                secureTextEntry={secureTextEntry}
                autoCorrect={false}
                ref={e => (inputs.current["password"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "password"
                }
                textContentType="password"
                label="New Password"
                autoCapitalize="none"
                returnKeyLabel="done"
                returnKeyType="done"
                onSubmitEditing={onSubmit}
                error={!!errors.password}
                right={
                  <TextInput.Icon
                    name={!!secureTextEntry ? "eye-off" : "eye"} // or can be a node where <Icon /> is any component from vector-icons or anything else
                    onPress={() =>
                      setSecureTextEntry(secureTextEntry => !secureTextEntry)
                    }
                  />
                }
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible>
                Enter a new Password to change your existing Password
              </HelperText>
              <HelperText type="error" visible={!!errors.password}>
                {errors.password?.message}
              </HelperText>
            </>
          )}
          name="password"
          defaultValue=""
        />
      )}
      {view === "MOBILE_APPROVAL" && (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginBottom: theme.spacing.xl,
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              <Text>
                I have read and agree to PropSwap's&nbsp;
                <Text
                  onPress={() =>
                    openUrl(
                      `${environment.base_url}/pages/terms-and-conditions.html`
                    )
                  }
                  style={[styles.textPrimary, styles.pointer]}
                >
                  Terms & Conditions
                </Text>
                &nbsp;and&nbsp;
                <Text
                  onPress={() =>
                    openUrl(`${environment.base_url}/pages/privacy-policy.html`)
                  }
                  style={[styles.textPrimary, styles.pointer]}
                >
                  Privacy Policy
                </Text>
                .
              </Text>
            </View>
          )}
          defaultValue={false}
          name="agree"
        />
      )}
    </ScrollElement>
    {!readOnly && (
      <ScreenBottom adjustPadding inset>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={onSubmit}
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </ScreenBottom>
    )}
  </>
);
