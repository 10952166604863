import Screen from "Components/Screen";
import { useTheme } from "react-native-paper";

import WatchingIconView from "Components/Watching/Icon/WatchingIconView";
import { View } from "react-native";
import TicketItem from "Components/Ticket/Item";
import Watching from "shared/api/Watching";
import { useMemo, useCallback, useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import DataList from "Components/DataList";
import ScreenHeader from "Components/Screen/Header";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "listWatchingsByUserId",
      query: Watching.queries.listByUserId,
      variables: {
        userId: currentUser?.id ?? "<>",
        sortDirection: "DESC",
        withListing: true,
      },
    }),
    [currentUser]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) =>
      !["INACTIVE", "ACTIVE"].includes(item.listing.status) ? null : (
        <View style={{ marginBottom: theme.spacing.md }}>
          <TicketItem
            ticketObj={item.listing.cachedTicket}
            listingObj={item.listing}
          />
        </View>
      ),
    [theme]
  );

  return (
    <Screen fullHeight fullWidth title="Watch List">
      <ScreenHeader
        title="Watch List"
        icon={<WatchingIconView theme={theme} color={theme.colors.onSurface} />}
        rightButton={null}
        navigation={navigation}
        theme={theme}
      />
      <DataList
        query={dataListQuery}
        renderItem={dataListRenderItem}
        contentContainerStyle={{
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
      />
    </Screen>
  );
};
