import gql from "graphql-tag";
import environment from "environment";

const Fragments = {};

Fragments.ExchangeTransaction = {
  public: gql`
    fragment ExchangeTransactionPublic on ExchangeTransaction {
      id
      createdAt
      updatedAt
      userId
      ticketId
      providerId
      amount
      type
    }
  `,
};

Fragments.User = {
  public: gql`
    fragment UserPublic on User {
      id
      # legacyId
      displayName
      profilePicUri
      createdAt
      updatedAt
      agreedToTermsAt
      verifiedAt
      allowListingWithoutAuth
      agreedToCcTermsAt
      rating
      status
      points
      availablePoints
      buyOrSellTransactionCount
      referralCode
      notificationsCount
      totalReferralBonus
      hideNotificationsCount
      sport {
        id
        name
        abbrev
      }
      team {
        id
        name
        location
        abbrev
      }
    }
  `,
  private: gql`
    fragment UserPrivate on User {
      email
      promotionalBalance
      pendingBalance
      balance
      bluesnapVaultedShopperId
      interchecksRecipientId
      lockRating
      lockStatus
      interchecksRecipientW9Required
      interchecksRecipientW9Verified
      interchecksRecipientW9Date
      emailNotifications
      pushNotifications
    }
  `,
};

Fragments.Event = {
  public: gql`
    fragment EventPublic on Event {
      id
      sportId
      userId
      groupId
      description
      active
      featuredAt
      createdAt
      updatedAt
      bovadoGroupId
      bovadoEventId
      bovadoMarketId
      fanDuelMarketId
      fanDuelEventId
      rebateMessage
      rebateActive
      realtime
      aliases
      parlayAlias
      viewCount

      isLive
      endAt
      liveStartAt
      liveEndAt
      updateInterval
      lastOddsAt
      updateIntervalIsActive
      disableValues
      sport {
        id
        name
        abbrev
      }
      group {
        id
        name
      }
    }
  `,
};

Fragments.Bid = {
  public: gql`
    fragment BidPublic on Bid {
      id
      createdAt
      updatedAt
      offer
      counter
      status
      bidListingId
      bidBidderId
    }
  `,
};

Fragments.Transaction = {
  public: gql`
    fragment TransactionPublic on Transaction {
      id
      createdAt
      updatedAt
      transactionId
      amount
      source
      type
      status
      note
      transactionUserId
      balanceSnapshot
      pendingBalanceSnapshot
      promotionalBalanceSnapshot
      transactionPromotionId
      transactionRedemptionId
    }
  `,
};

Fragments.Watching = {
  public: gql`
    fragment WatchingPublic on Watching {
      id
      createdAt
      updatedAt
      listingId
      userId
    }
  `,
};

Fragments.Activity = {
  public: gql`
    fragment ActivityPublic on Activity {
      id
      createdAt
      updatedAt
      userId

      model
      modelId
      title
      body
      type
      data

      notificationCenterEligible
      globalEligible

      user {
        id
        displayName
      }
    }
  `,
};

Fragments.Sportsbook = {
  public: gql`
    fragment SportsbookPublic on Sportsbook {
      id
      createdAt
      updatedAt
      name
      active
    }
  `,
};

Fragments.Ticket = {
  public: gql`
    fragment TicketPublic on Ticket {
      id
      # legacyId
      createdAt
      updatedAt
      odds
      oddsCalculated
      photoUrl
      betType
      eventDescription
      subject
      winAmount
      betAmount
      collectAmount
      additionalInfo
      ticketId
      bookName
      isMobile
      creatorId
      source
      result
      inPropswapsPossession
      reviewed
      ticketUserId
      ticketSportId
      ticketOriginalOwnerId
      ticketLocaleId
      ticketSportsbookId
      ticketEventId
      ticketOptionId
      hasShipped
      event {
        id
        description
        active
        featuredAt
        createdAt
        updatedAt
        bovadoGroupId
        bovadoEventId
        fanDuelMarketId
        fanDuelEventId
        rebateMessage
        rebateActive
      }

      option {
        id
        name
        active
        createdAt
        bovadoParticipantId
        bovadoParticipantName
        fanDuelSelectionId
      }

      sportsbook {
        id
        name
      }

      sport {
        id
        abbrev
        name
      }

      locale {
        id
        name
        state
        mailingAddress
      }

      views
    }
  `,
};

Fragments.Locale = {
  public: gql`
    fragment LocalePublic on Locale {
      id
      name
      state
      mailingAddress
      createdAt
      updatedAt
      additionalEmails
    }
  `,
};

Fragments.Sport = {
  public: gql`
    fragment SportPublic on Sport {
      id
      name
      abbrev
      feedId
      position
    }
  `,
};

Fragments.Group = {
  public: gql`
    fragment GroupPublic on Group {
      id
      sportId
      userId
      name
      createdAt
      updatedAt
      active

      sport {
        ...SportPublic
      }
    }
    ${Fragments.Sport.public}
  `,
};

Fragments.Option = {
  public: gql`
    fragment OptionPublic on Option {
      id
      sportId
      userId
      name
      createdAt
      updatedAt
      active
      bovadoParticipantId
      bovadoParticipantName
      fanDuelSelectionId
      aliases
      viewCount
      sport {
        ...SportPublic
      }
    }
    ${Fragments.Sport.public}
  `,
};

Fragments.Search = {
  public: gql`
    fragment SearchPublic on Search {
      id
      userId
      text
      user {
        ...UserPublic
      }
    }
    ${Fragments.User.public}
  `,
};

Fragments.OptionGroup = {
  public: gql`
    fragment OptionGroupPublic on OptionGroup {
      id
      optionId
      groupId

      option {
        ...OptionPublic
      }

      group {
        ...GroupPublic
      }
    }
    ${Fragments.Option.public}
    ${Fragments.Group.public}
  `,
};

Fragments.Campaign = {
  public: gql`
    fragment CampaignPublic on Campaign {
      id
      createdAt
      updatedAt
      name
      isExpired
      expiresAt
    }
  `,
};

Fragments.ConversionTemplate = {
  public: gql`
    fragment ConversionTemplatePublic on ConversionTemplate {
      id
      createdAt
      updatedAt

      bonusCode
      mapping {
        type
        threshold
        referrerBonus
        referreeBonus
      }
      active
    }
  `,
};

Fragments.Referral = {
  public: gql`
    fragment ReferralPublic on Referral {
      id
      createdAt
      updatedAt

      stage
      redeemed
      expired
      code
      bonusCode
      referrerId
      referreeId
      conversionTemplateId
      referreeBounusAmount
      referreeDepositAmount
      referrerBounusAmount
      conversionTemplate {
        bonusCode
        mapping {
          type
          threshold
          referrerBonus
          referreeBonus
        }
        active
      }
    }
  `,
};

Fragments.Promotion = {
  public: gql`
    fragment PromotionPublic on Promotion {
      id
      code
      type
      promotionUserId
      expiresAt
      isExpired
      createdAt
      updatedAt
      promotionCampaignId
      campaign {
        ...CampaignPublic
      }
    }
    ${Fragments.Campaign.public}
  `,
};

Fragments.Redemption = {
  public: gql`
    fragment RedemptionPublic on Redemption {
      id
      redemptionPromotionId
      redemptionUserId
      redemptionCampaignId
      createdAt
      updatedAt
      promotion {
        ...PromotionPublic
      }
    }
    ${Fragments.Promotion.public}
  `,
};

Fragments.Listing = {
  public: gql`
    fragment ListingPublic on Listing {
      id
      # legacyId
      createdAt
      updatedAt
      expiresAt
      soldAt
      bidCount
      salesPrice
      askingPrice
      minimumBidPrice
      newOdds
      newOddsCalculated
      status
      ratingOverride
      featuredAt
      listingTicketId
      listingBuyerId
      listingSellerId
      searchString
      score
      recentlySold
      postedFrom
      boughtFrom
      valueRating
      rebateStatus
      notes
      commissionWaiverApplied
      pendingBidsCount
      cachedTicket {
        id
        # legacyId
        createdAt
        updatedAt
        odds
        oddsCalculated
        photoUrl
        betType
        eventDescription
        subject
        winAmount
        betAmount
        collectAmount
        additionalInfo
        ticketId
        bookName
        isMobile
        creatorId
        source
        result
        inPropswapsPossession
        reviewed
        ticketUserId
        ticketSportId
        ticketOriginalOwnerId
        ticketLocaleId
        ticketSportsbookId
        ticketEventId
        ticketOptionId
        hasShipped
        event {
          id
          description
          active
          featuredAt
          createdAt
          updatedAt
          bovadoGroupId
          bovadoEventId
          rebateMessage
          rebateActive
        }

        option {
          id
          name
          active
          createdAt
          bovadoParticipantId
          bovadoParticipantName
          fanDuelSelectionId
        }

        sportsbook {
          id
          name
        }

        sport {
          id
          abbrev
          name
        }

        locale {
          id
          name
          state
          mailingAddress
        }

        cachedBets {
          id
          eventDescription
          subject
        }

        views
      }
    }
  `,
  publicWithBids: gql`
    fragment ListingPublicWithBids on Listing {
      id
      # legacyId
      createdAt
      updatedAt
      expiresAt
      bidCount
      salesPrice
      askingPrice
      newOdds
      newOddsCalculated
      status
      ratingOverride
      featuredAt
      listingTicketId
      listingBuyerId
      listingSellerId
      recentlySold
      postedFrom
      boughtFrom
      valueRating
      rebateStatus
      notes
      bids {
        items {
          ...BidPublic
        }
      }
    }
    ${Fragments.Bid.public}
  `,
};

Fragments.Redirect = {
  public: gql`
    fragment RedirectPublic on Redirect {
      id
      updatedAt
      createdAt

      source
      destination
    }
  `,
};

Fragments.Link = {
  public: gql`
    fragment LinkPublic on Link {
      id
      updatedAt
      createdAt

      title
      description
      photoUrl
      position
      url
      active
    }
  `,
};

Fragments.Device = {
  public: gql`
    fragment DevicePublic on Device {
      id
      userId
      type
      updatedAt
      createdAt
      token
      user {
        ...UserPublic
      }
    }
    ${Fragments.User.public}
  `,
};

Fragments.OptionEventOdds = {
  public: gql`
    fragment OptionEventOddsPublic on OptionEventOdds {
      id
      userId
      eventId
      optionId

      createdAt
      updatedAt

      oddsFormated
      oddsCalculated

      option {
        ...OptionPublic
      }
      event {
        ...EventPublic
      }
    }
    ${Fragments.Event.public}
    ${Fragments.Option.public}
  `,
};

Fragments.SportsbookLocale = {
  public: gql`
    fragment SportsbookLocalePublic on SportsbookLocale {
      id
      createdAt
      updatedAt
      sportsbook {
        ...SportsbookPublic
      }
      locale {
        ...LocalePublic
      }
    }
    ${Fragments.Locale.public}
    ${Fragments.Sportsbook.public}
  `,
};

Fragments.TicketView = {
  public: gql`
    fragment TicketViewPublic on TicketView {
      id
      createdAt
      updatedAt
      ticketViewUserId
      ticketId
      user {
        ...UserPublic
      }
      ticket {
        ...TicketPublic
      }
    }
    ${Fragments.User.public}
    ${Fragments.Ticket.public}
  `,
};

const Redirect = {
  mutations: {
    update: gql`
      mutation UpdateRedirect($input: UpdateRedirectInput!) {
        updateRedirect(input: $input) {
          ...RedirectPublic
        }
      }
      ${Fragments.Redirect.public}
    `,
    create: gql`
      mutation CreateRedirect($input: CreateRedirectInput!) {
        createRedirect(input: $input) {
          ...RedirectPublic
        }
      }
      ${Fragments.Redirect.public}
    `,
    delete: gql`
      mutation DeleteRedirect($input: DeleteRedirectInput!) {
        deleteRedirect(input: $input) {
          ...RedirectPublic
        }
      }
      ${Fragments.Redirect.public}
    `,
  },
  queries: {
    listBySource: gql`
      query ListRedirectsBySource(
        $source: String
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelRedirectFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listRedirectsBySource(
          source: $source
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...RedirectPublic
          }
          nextToken
        }
      }
      ${Fragments.Redirect.public}
    `,
    get: gql`
      query GetRedirect($id: ID!) {
        getRedirect(id: $id) {
          ...RedirectPublic
        }
      }
      ${Fragments.Redirect.public}
    `,
    list: gql`
      query ListRedirects(
        $filter: ModelRedirectFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listRedirects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...RedirectPublic
          }
          nextToken
        }
      }
      ${Fragments.Redirect.public}
    `,
  },
};

const Link = {
  mutations: {
    update: gql`
      mutation UpdateLink($input: UpdateLinkInput!) {
        updateLink(input: $input) {
          ...LinkPublic
        }
      }
      ${Fragments.Link.public}
    `,
    create: gql`
      mutation CreateLink($input: CreateLinkInput!) {
        createLink(input: $input) {
          ...LinkPublic
        }
      }
      ${Fragments.Link.public}
    `,
    delete: gql`
      mutation DeleteLink($input: DeleteLinkInput!) {
        deleteLink(input: $input) {
          ...LinkPublic
        }
      }
      ${Fragments.Link.public}
    `,
  },
  queries: {
    listByPosition: gql`
      query ListLinksByPosition(
        $active: StringBoolean
        $position: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelLinkFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listLinksByPosition(
          active: $active
          position: $position
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...LinkPublic
          }
          nextToken
        }
      }
      ${Fragments.Link.public}
    `,
    get: gql`
      query GetLink($id: ID!) {
        getLink(id: $id) {
          ...LinkPublic
        }
      }
      ${Fragments.Link.public}
    `,
    list: gql`
      query ListLinks(
        $filter: ModelLinkFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...LinkPublic
          }
          nextToken
        }
      }
      ${Fragments.Link.public}
    `,
  },
};

const Device = {
  mutations: {
    update: gql`
      mutation UpdateDevice($input: UpdateDeviceInput!) {
        updateDevice(input: $input) {
          ...DevicePublic
        }
      }
      ${Fragments.Device.public}
    `,
    create: gql`
      mutation CreateDevice($input: CreateDeviceInput!) {
        createDevice(input: $input) {
          ...DevicePublic
        }
      }
      ${Fragments.Device.public}
    `,
  },
  queries: {
    listByUserId: gql`
      query ListDevicesByUserId(
        $userId: ID
        $updatedAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeviceFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listDevicesByUserId(
          userId: $userId
          updatedAt: $updatedAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...DevicePublic
          }
          nextToken
        }
      }
      ${Fragments.Device.public}
    `,
    listByTokenAndType: gql`
      query ListDevicesByTokenAndType(
        $token: String
        $type: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeviceFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listDevicesByTokenAndType(
          token: $token
          type: $type
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...DevicePublic
          }
          nextToken
        }
      }
      ${Fragments.Device.public}
    `,
    get: gql`
      query GetDevice($id: ID!) {
        getDevice(id: $id) {
          ...DevicePublic
        }
      }
      ${Fragments.Device.public}
    `,
    list: gql`
      query ListDevices(
        $filter: ModelDeviceFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...DevicePublic
          }
          nextToken
        }
      }
      ${Fragments.Device.public}
    `,
  },
};

const Locale = {};

Locale.queries = {
  list: gql`
    query ListLocales(
      $filter: ModelLocaleFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listLocales(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...LocalePublic
        }
      }
    }
    ${Fragments.Locale.public}
  `,
  get: gql`
    query GetLocale($id: ID!) {
      getLocale(id: $id) {
        ...LocalePublic
      }
    }
    ${Fragments.Locale.public}
  `,
};

Locale.mutations = {
  create: gql`
    mutation CreateLocale($input: CreateLocaleInput!) {
      createLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Fragments.Locale.public}
  `,
  update: gql`
    mutation UpdateLocale($input: UpdateLocaleInput!) {
      updateLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Fragments.Locale.public}
  `,
  delete: gql`
    mutation DeleteLocale($input: DeleteLocaleInput!) {
      deleteLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Fragments.Locale.public}
  `,
};

const ExchangeTransaction = {};

ExchangeTransaction.queries = {
  list: gql`
    query ListExchangeTransactions(
      $filter: ModelExchangeTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listExchangeTransactions(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ExchangeTransactionPublic
        }
      }
    }
    ${Fragments.ExchangeTransaction.public}
  `,
  get: gql`
    query GetExchangeTransaction($id: ID!) {
      getExchangeTransaction(id: $id) {
        ...ExchangeTransactionPublic
      }
    }
    ${Fragments.ExchangeTransaction.public}
  `,
};

ExchangeTransaction.mutations = {
  create: gql`
    mutation CreateExchangeTransaction(
      $input: CreateExchangeTransactionInput!
    ) {
      createExchangeTransaction(input: $input) {
        ...ExchangeTransactionPublic
      }
    }
    ${Fragments.ExchangeTransaction.public}
  `,
  update: gql`
    mutation UpdateExchangeTransaction(
      $input: UpdateExchangeTransactionInput!
    ) {
      updateExchangeTransaction(input: $input) {
        ...ExchangeTransactionPublic
      }
    }
    ${Fragments.ExchangeTransaction.public}
  `,
  delete: gql`
    mutation DeleteExchangeTransaction(
      $input: DeleteExchangeTransactionInput!
    ) {
      deleteExchangeTransaction(input: $input) {
        ...ExchangeTransactionPublic
      }
    }
    ${Fragments.ExchangeTransaction.public}
  `,
};

const Watching = {};

Watching.queries = {
  listByUserIdAndListingId: gql`
    query ListWatchingsByUserIdAndListingId(
      $userId: ID
      $listingIdCreatedAt: ModelWatchingByUserIdAndListingIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByUserIdAndListingId(
        userId: $userId
        listingIdCreatedAt: $listingIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Watching.public}
    ${Fragments.User.public}
    ${Fragments.Listing.public}
  `,
  listByUserId: gql`
    query ListWatchingsByUserId(
      $userId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withTicket: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByUserId(
        userId: $userId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
            ticket @include(if: $withTicket) {
              ...TicketPublic
            }
          }
        }
        nextToken
      }
    }
    ${Fragments.Watching.public}
    ${Fragments.User.public}
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
  `,
  listByListingId: gql`
    query ListWatchingsByListingId(
      $listingId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByListingId(
        listingId: $listingId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Watching.public}
    ${Fragments.User.public}
    ${Fragments.Listing.public}
  `,
  list: gql`
    query ListWatchings(
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchings(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Watching.public}
    ${Fragments.User.public}
    ${Fragments.Listing.public}
  `,
  get: gql`
    query GetWatching($id: ID!) {
      getWatching(id: $id) {
        ...WatchingPublic
      }
    }
    ${Fragments.Watching.public}
  `,
};

Watching.mutations = {
  delete: gql`
    mutation DeleteWatching($input: DeleteWatchingInput!) {
      deleteWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Fragments.Watching.public}
  `,
  create: gql`
    mutation CreateWatching($input: CreateWatchingInput!) {
      createWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Fragments.Watching.public}
  `,
  update: gql`
    mutation UpdateWatching($input: UpdateWatchingInput!) {
      updateWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Fragments.Watching.public}
  `,
};

const Sport = {};

Sport.queries = {
  list: gql`
    query ListSports(
      $filter: ModelSportFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSports(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...SportPublic
        }
      }
    }
    ${Fragments.Sport.public}
  `,
  get: gql`
    query GetSport($id: ID!) {
      getSport(id: $id) {
        ...SportPublic
      }
    }
    ${Fragments.Sport.public}
  `,
};

Sport.mutations = {
  create: gql`
    mutation CreateSport($input: CreateSportInput!) {
      createSport(input: $input) {
        ...SportPublic
      }
    }
    ${Fragments.Sport.public}
  `,
  update: gql`
    mutation UpdateSport($input: UpdateSportInput!) {
      updateSport(input: $input) {
        ...SportPublic
      }
    }
    ${Fragments.Sport.public}
  `,
};

const User = {};

User.mutations = {
  convertPointsToCash: gql`
    mutation ConvertPointsToCash($input: ConvertPointsToCashInput!) {
      convertPointsToCash(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  update: gql`
    mutation UpdateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  verify: gql`
    mutation VerifyUser($input: VerifyUserInput!) {
      verifyUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  enable: gql`
    mutation EnableUser($input: EnableUserInput!) {
      enableUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  create: gql`
    mutation CreateUser($input: CreateUserInput!) {
      createUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
};

User.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateUser($id: String) {
      onUpdateUser(id: $id) {
        id
      }
    }
  `,
};

User.queries = {
  get: gql`
    query GetUser($id: ID!, $withPrivate: Boolean = false) {
      getUser(id: $id) {
        ...UserPublic
        ...UserPrivate @include(if: $withPrivate)
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  list: gql`
    query ListUsers(
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...UserPublic
        }
        nextToken
      }
    }
    ${Fragments.User.public}
  `,
  listByEmail: gql`
    query UsersByEmail(
      $email: String
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
      $withPrivate: Boolean = false
    ) {
      usersByEmail(
        email: $email
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...UserPublic
          ...UserPrivate @include(if: $withPrivate)
        }
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  listByDisplayName: gql`
    query ListUsersByDisplayName(
      $displayName: String
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
      $withPrivate: Boolean = false
    ) {
      listUsersByDisplayName(
        displayName: $displayName
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...UserPublic
          ...UserPrivate @include(if: $withPrivate)
        }
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  getPrivateWithBids: gql`
    query GetUserPrivateWithBids(
      $id: ID!
      $bidderBidsFilter: ModelBidFilterInput
      $bidderBidsLimit: Int
      $bidderBidsNextToken: String
    ) {
      getUser(id: $id) {
        ...UserPublic
        ...UserPrivate
        bidderBids(
          filter: $bidderBidsFilter
          limit: $bidderBidsLimit
          nextToken: $bidderBidsNextToken
        ) {
          items {
            ...BidPublic
          }
          nextToken
        }
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
    ${Fragments.Bid.public}
  `,
  getWithPrivate: gql`
    query GetUserWithPrivate($id: ID!) {
      getUser(id: $id) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
};

const Event = {};

Event.mutations = {
  update: gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
      updateEvent(input: $input) {
        ...EventPublic
      }
    }
    ${Fragments.Event.public}
  `,
  create: gql`
    mutation CreateEvent($input: CreateEventInput!) {
      createEvent(input: $input) {
        ...EventPublic
      }
    }
    ${Fragments.Event.public}
  `,
};

Event.queries = {
  get: gql`
    query GetEvent($id: ID!) {
      getEvent(id: $id) {
        ...EventPublic
      }
    }
    ${Fragments.Event.public}
  `,
  list: gql`
    query ListEvents(
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...EventPublic
        }
        nextToken
      }
    }
    ${Fragments.Event.public}
  `,
  listBySportId: gql`
    query ListEventsBySportId(
      $sportId: ID
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEventsBySportId(
        sportId: $sportId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EventPublic
        }
      }
    }
    ${Fragments.Event.public}
  `,
};

const OptionEventOdds = {};

OptionEventOdds.mutations = {
  update: gql`
    mutation UpdateOptionEventOdds($input: UpdateOptionEventOddsInput!) {
      updateOptionEventOdds(input: $input) {
        ...OptionEventOddsPublic
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  create: gql`
    mutation CreateOptionEventOdds($input: CreateOptionEventOddsInput!) {
      createOptionEventOdds(input: $input) {
        ...OptionEventOddsPublic
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
};

OptionEventOdds.queries = {
  get: gql`
    query GetOptionEventOddss($id: ID!) {
      getOptionEventOddss(id: $id) {
        ...OptionEventOddsPublic
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  list: gql`
    query ListOptionEventOddss(
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddss(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  listByEventId: gql`
    query ListOptionEventOddsByEventId(
      $eventId: ID
      $oddsCalculated: ModelFloatKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventId(
        eventId: $eventId
        oddsCalculated: $oddsCalculated
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  listByEventIdAndCreatedAt: gql`
    query ListOptionEventOddsByEventIdAndCreatedAt(
      $eventId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventIdAndCreatedAt(
        eventId: $eventId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  listByEventIdAndOptionId: gql`
    query ListOptionEventOddsByEventIdAndOptionId(
      $eventId: ID
      $optionIdCreatedAt: ModelOptionEventOddsByEventIdAndOptionIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventIdAndOptionId(
        eventId: $eventId
        optionIdCreatedAt: $optionIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
  listByOptionId: gql`
    query ListOptionEventOddsByOptionId(
      $optionId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByOptionId(
        optionId: $optionId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionEventOdds.public}
  `,
};

const Group = {};

Group.mutations = {
  update: gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
      updateGroup(input: $input) {
        ...GroupPublic
      }
    }
    ${Fragments.Group.public}
  `,
  create: gql`
    mutation CreateGroup($input: CreateGroupInput!) {
      createGroup(input: $input) {
        ...GroupPublic
      }
    }
    ${Fragments.Group.public}
  `,
};

Group.queries = {
  get: gql`
    query GetGroup($id: ID!) {
      getGroup(id: $id) {
        ...GroupPublic
      }
    }
    ${Fragments.Group.public}
  `,
  list: gql`
    query ListGroups(
      $filter: ModelGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...GroupPublic
        }
        nextToken
      }
    }
    ${Fragments.Group.public}
  `,
  listBySportId: gql`
    query ListGroupsBySportId(
      $sportId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listGroupsBySportId(
        sportId: $sportId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...GroupPublic
        }
      }
    }
    ${Fragments.Group.public}
  `,
};

const Option = {};

Option.mutations = {
  update: gql`
    mutation UpdateOption($input: UpdateOptionInput!) {
      updateOption(input: $input) {
        ...OptionPublic
      }
    }
    ${Fragments.Option.public}
  `,
  create: gql`
    mutation CreateOption($input: CreateOptionInput!) {
      createOption(input: $input) {
        ...OptionPublic
      }
    }
    ${Fragments.Option.public}
  `,
};

Option.queries = {
  get: gql`
    query GetOption($id: ID!) {
      getOption(id: $id) {
        ...OptionPublic
      }
    }
    ${Fragments.Option.public}
  `,
  list: gql`
    query ListOptions(
      $filter: ModelOptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...OptionPublic
        }
        nextToken
      }
    }
    ${Fragments.Option.public}
  `,
  listBySportId: gql`
    query ListOptionsBySportId(
      $sportId: ID
      $name: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionsBySportId(
        sportId: $sportId
        name: $name
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionPublic
        }
      }
    }
    ${Fragments.Option.public}
  `,
};

const OptionGroup = {};

OptionGroup.mutations = {
  update: gql`
    mutation UpdateOptionGroup($input: UpdateOptionGroupInput!) {
      updateOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${Fragments.OptionGroup.public}
  `,
  create: gql`
    mutation CreateOptionGroup($input: CreateOptionGroupInput!) {
      createOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${Fragments.OptionGroup.public}
  `,
  delete: gql`
    mutation DeleteOptionGroup($input: DeleteOptionGroupInput!) {
      deleteOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${Fragments.OptionGroup.public}
  `,
};

OptionGroup.queries = {
  get: gql`
    query GetOptionGroup($id: ID!) {
      getOptionGroup(id: $id) {
        ...OptionGroupPublic
      }
    }
    ${Fragments.OptionGroup.public}
  `,
  list: gql`
    query ListOptionGroups(
      $filter: ModelOptionGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...OptionGroupPublic
        }
        nextToken
      }
    }
    ${Fragments.OptionGroup.public}
  `,
  listByGroupId: gql`
    query ListOptionGroupsByGroupId(
      $groupId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionGroupsByGroupId(
        groupId: $groupId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionGroupPublic
        }
      }
    }
    ${Fragments.OptionGroup.public}
  `,
};

const Ticket = {};

Ticket.mutations = {
  update: gql`
    mutation UpdateTicket($input: UpdateTicketInput!) {
      updateTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Fragments.Ticket.public}
  `,
  create: gql`
    mutation CreateTicket($input: CreateTicketInput!) {
      createTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Fragments.Ticket.public}
  `,
  delete: gql`
    mutation DeleteTicket($input: DeleteTicketInput!) {
      deleteTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Fragments.Ticket.public}
  `,
};

Ticket.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateTicket {
      onUpdateTicket {
        ...TicketPublic
      }
    }
    ${Fragments.Ticket.public}
  `,
  onCreate: gql`
    subscription OnCreateTicket {
      onCreateTicket {
        ...TicketPublic
      }
    }
    ${Fragments.Ticket.public}
  `,
};

Ticket.queries = {
  get: gql`
    query GetTicket(
      $id: ID!
      $listingsFilter: ModelListingFilterInput
      $listingsLimit: Int
      $listingsNextToken: String
    ) {
      getTicket(id: $id) {
        ...TicketPublic
        listings(
          filter: $listingsFilter
          limit: $listingsLimit
          nextToken: $listingsNextToken
        ) {
          items {
            ...ListingPublic
          }
          nextToken
        }
      }
    }
    ${Fragments.Ticket.public}
    ${Fragments.Listing.public}
  `,
  listBySportsbookId: gql`
    query ListTicketsBySportsbookId(
      $ticketSportsbookId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listTicketsBySportsbookId(
        ticketSportsbookId: $ticketSportsbookId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
  `,
  listByUserId: gql`
    query TicketsByUserId(
      $ticketUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByUserId(
        ticketUserId: $ticketUserId
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
  `,
  listByResultUpdatedAt: gql`
    query TicketsByResultUpdatedAt(
      $result: BetResult!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByResultUpdatedAt(
        result: $result
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
  `,
  listByResultAndViews: gql`
    query ListTicketsByResultAndViews(
      $result: BetResult!
      $views: ModelIntKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listTicketsByResultAndViews(
        result: $result
        views: $views
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
  `,
  listByUserIdWithListingsAndBids: gql`
    query TicketsByUserId(
      $ticketUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
      $listingsFilter: ModelListingFilterInput
      $listingsLimit: Int
      $listingsNextToken: String
      $listingsSortDirection: ModelSortDirection
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
    ) {
      ticketsByUserId(
        ticketUserId: $ticketUserId
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
          listings(
            filter: $listingsFilter
            limit: $listingsLimit
            nextToken: $listingsNextToken
            sortDirection: $listingsSortDirection
          ) {
            items {
              ...ListingPublic
              bids(
                filter: $bidsFilter
                limit: $bidsLimit
                nextToken: $bidsNextToken
                sortDirection: $bidsSortDirection
              ) {
                items {
                  ...BidPublic
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
    ${Fragments.Listing.public}
    ${Fragments.Bid.public}
  `,
  list: gql`
    query ListTickets(
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Fragments.Ticket.public}
  `,
};

const Sportsbook = {};

Sportsbook.mutations = {
  update: gql`
    mutation UpdateSportsbook($input: UpdateSportsbookInput!) {
      updateSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
  create: gql`
    mutation CreateSportsbook($input: CreateSportsbookInput!) {
      createSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
  delete: gql`
    mutation DeleteSportsbook($input: DeleteSportsbookInput!) {
      deleteSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
};

Sportsbook.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateSportsbook {
      onUpdateSportsbook {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
  onCreate: gql`
    subscription OnCreateSportsbook {
      onCreateSportsbook {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
};

Sportsbook.queries = {
  get: gql`
    query GetSportsbook($id: ID!) {
      getSportsbook(id: $id) {
        ...SportsbookPublic
      }
    }
    ${Fragments.Sportsbook.public}
  `,
  list: gql`
    query ListSportsbooks(
      $filter: ModelSportsbookFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSportsbooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...SportsbookPublic
        }
        nextToken
      }
    }
    ${Fragments.Sportsbook.public}
  `,
};

const Listing = {};

Listing.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateListingPublic {
      onUpdateListingPublic {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  onCreate: gql`
    subscription OnCreateListing {
      onCreateListing {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
};

Listing.queries = {
  listRecommendedByUser: gql`
    query ListRecommendedListingsByUser(
      $userId: ID!
      $includeInactive: Boolean
    ) {
      listRecommendedListingsByUser(
        userId: $userId
        includeInactive: $includeInactive
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
  `,
  list: gql`
    query ListListings(
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
        }
        nextToken
      }
    }
  `,
  searchRaw: gql`
    query SearchListingsRaw(
      $filter: AWSJSON
      $sort: SearchableListingRawSortInput
      $limit: Int
      $nextToken: String
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
      $withSeller: Boolean = false
      $withBuyer: Boolean = false
      $withTicketUser: Boolean = false
      $withTicketOriginalOwner: Boolean = false
    ) {
      searchListingsRaw(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
            user @include(if: $withTicketUser) {
              ...UserPublic
            }
            originalOwner @include(if: $withTicketOriginalOwner) {
              ...UserPublic
            }
          }
          bids(
            filter: $bidsFilter
            limit: $bidsLimit
            nextToken: $bidsNextToken
            sortDirection: $bidsSortDirection
          ) {
            items {
              ...BidPublic
            }
            nextToken
          }
          seller @include(if: $withSeller) {
            ...UserPublic
          }
          buyer @include(if: $withBuyer) {
            ...UserPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.Bid.public}
    ${Fragments.User.public}
  `,
  search: gql`
    query SearchListings(
      $filter: SearchableListingFilterInput
      $sort: SearchableListingSortInput
      $limit: Int
      $nextToken: String
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
      $withSeller: Boolean = false
      $withBuyer: Boolean = false
      $withTicketUser: Boolean = false
      $withTicketOriginalOwner: Boolean = false
    ) {
      searchListings(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
            user @include(if: $withTicketUser) {
              ...UserPublic
            }
            originalOwner @include(if: $withTicketOriginalOwner) {
              ...UserPublic
            }
          }
          bids(
            filter: $bidsFilter
            limit: $bidsLimit
            nextToken: $bidsNextToken
            sortDirection: $bidsSortDirection
          ) {
            items {
              ...BidPublic
            }
            nextToken
          }
          seller @include(if: $withSeller) {
            ...UserPublic
          }
          buyer @include(if: $withBuyer) {
            ...UserPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.Bid.public}
    ${Fragments.User.public}
  `,
  get: gql`
    query GetListing(
      $id: ID!
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
      $withSeller: Boolean = false
      $withBuyer: Boolean = false
      $withTicketUser: Boolean = false
      $withTicketOriginalOwner: Boolean = false
    ) {
      getListing(id: $id) {
        ...ListingPublic
        ticket {
          ...TicketPublic
          user @include(if: $withTicketUser) {
            ...UserPublic
          }
          originalOwner @include(if: $withTicketOriginalOwner) {
            ...UserPublic
          }
        }
        bids(
          filter: $bidsFilter
          limit: $bidsLimit
          nextToken: $bidsNextToken
          sortDirection: $bidsSortDirection
        ) {
          items {
            ...BidPublic
          }
          nextToken
        }
        seller @include(if: $withSeller) {
          ...UserPublic
        }
        buyer @include(if: $withBuyer) {
          ...UserPublic
        }
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.Bid.public}
    ${Fragments.User.public}
  `,
  listByTicketId: gql`
    query ListingsByTicketId(
      $listingTicketId: ID
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
      $withSeller: Boolean = false
      $withBuyer: Boolean = false
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
    ) {
      listingsByTicketId(
        listingTicketId: $listingTicketId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
          seller @include(if: $withSeller) {
            ...UserPublic
          }
          buyer @include(if: $withBuyer) {
            ...UserPublic
          }
          bids(
            filter: $bidsFilter
            limit: $bidsLimit
            nextToken: $bidsNextToken
            sortDirection: $bidsSortDirection
          ) {
            items {
              ...BidPublic
            }
            nextToken
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.User.public}
    ${Fragments.Bid.public}
  `,
  listBySellerId: gql`
    query ListingsBySellerId(
      $listingSellerId: ID
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
    ) {
      listingsBySellerId(
        listingSellerId: $listingSellerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
          bids(
            filter: $bidsFilter
            limit: $bidsLimit
            nextToken: $bidsNextToken
            sortDirection: $bidsSortDirection
          ) {
            items {
              ...BidPublic
            }
            nextToken
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.Bid.public}
  `,
  listByBuyerId: gql`
    query ListingsByBuyerId(
      $listingBuyerId: ID
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsByBuyerId(
        listingBuyerId: $listingBuyerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
  `,
  listBySellerIdWithBids: gql`
    query ListingsBySellerId(
      $listingSellerId: ID
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
    ) {
      listingsBySellerId(
        listingSellerId: $listingSellerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
          bids(
            filter: $bidsFilter
            limit: $bidsLimit
            nextToken: $bidsNextToken
            sortDirection: $bidsSortDirection
          ) {
            items {
              ...BidPublic
            }
            nextToken
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
    ${Fragments.Bid.public}
  `,
  listByStatus: gql`
    query ListingsByStatus(
      $status: ListingStatus!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $sortDirection: ModelSortDirection
      $limit: Int
      $nextToken: String
    ) {
      listingsByStatus(
        status: $status
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
  `,
  listByStatusAndExpiresAt: gql`
    query ListListingByStatusAndExpiresAt(
      $status: ${environment.use_v2 ? "String!" : "ListingStatus"}
      $expiresAt: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $sortDirection: ModelSortDirection
      $limit: Int
      $nextToken: String
    ) {
      listListingByStatusAndExpiresAt(
        status: $status
        expiresAt: $expiresAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Ticket.public}
  `,
  listByStatusWithBids: gql`
    query ListingsByStatus(
      $status: ListingStatus!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $sortDirection: ModelSortDirection
      $limit: Int
      $nextToken: String
    ) {
      listingsByStatus(
        status: $status
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublicWithBids
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.publicWithBids}
    ${Fragments.Ticket.public}
  `,
};

Listing.mutations = {
  buy: gql`
    mutation BuyListing($input: BuyListingInput!) {
      buyListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  pause: gql`
    mutation PauseListing($input: UpdateListingStatusInput!) {
      pauseListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  reactivate: gql`
    mutation ReactiviateListing($input: UpdateListingStatusInput!) {
      reactivateListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  update: gql`
    mutation UpdateListing($input: UpdateListingInput!) {
      updateListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  createRaw: gql`
    mutation CreateListing($input: CreateListingInput!) {
      createListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
  create: gql`
    mutation CreateNewListing($input: CreateNewListingInput!) {
      createNewListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Fragments.Listing.public}
  `,
};

const Interchecks = {};

Interchecks.mutations = {
  createPayment: gql`
    mutation CreateInterchecksPayment($input: CreateInterchecksPaymentInput!) {
      createInterchecksPayment(input: $input) {
        request_id
        request_status
        payments_processed
        payments_created
        payments_failed
        recipient {
          recipient_id
          recipient_email
          recipient_first_name
          recipient_last_name
          payments {
            payment_id
            payment_amount
            payment_reference_id
            payment_currency
            payment_status
            payment_created_date
          }
          payout_transaction {
            payout_id
            payout_amount
            payout_currency
            payout_status
            payout_date
            payout_transaction_id
            payout_method
            payout_destination
          }
        }
      }
    }
  `,
};

const Bovado = {};

Bovado.queries = {
  getOddsDataForEvent: gql`
    query GetOddsDataForEvent(
      $bovadoGroupId: String!
      $bovadoEventId: String!
    ) {
      getOddsDataForEvent(
        bovadoGroupId: $bovadoGroupId
        bovadoEventId: $bovadoEventId
      )
    }
  `,
  getOddsDataForEventAndOption: gql`
    query GetOddsDataForEventAndOption(
      $bovadoGroupId: String!
      $bovadoEventId: String!
      $bovadoParticipantId: String
      $bovadoParticipantName: String
      $bovadoMarketId: String
    ) {
      getOddsDataForEventAndOption(
        bovadoGroupId: $bovadoGroupId
        bovadoEventId: $bovadoEventId
        bovadoParticipantId: $bovadoParticipantId
        bovadoParticipantName: $bovadoParticipantName
        bovadoMarketId: $bovadoMarketId
      ) {
        currentpricedown
        currentpriceup
        currentscore
        estimatepricedown
        estimatepriceup
        idfomarket
        idfoparticipant
        idfoselection
        name
        price
        shortname
        sppricedown
        sppriceup
      }
    }
  `,
};

const Cognito = {};

Cognito.queries = {
  dump: gql`
    query Dump {
      dump
    }
  `,
};

const Notifications = {};

Notifications.mutations = {
  create: gql`
    mutation CreateNotification($input: CreateNotificationInput!) {
      createNotification(input: $input)
    }
  `,
};

const Bluesnap = {};

Bluesnap.queries = {
  getTransaction: gql`
    query GetBluesnapTransaction($transactionId: String!) {
      getBluesnapTransaction(transactionId: $transactionId) {
        amount
        transactionId
        vaultedShopperId
        currency
        cardTransactionType
        processingInfo {
          avsResponseCodeAddress
          cvvResponseCode
          avsResponseCodeName
          avsResponseCodeZip
          processingStatus
        }
      }
    }
  `,
  getVaultedShopper: gql`
    query GetBluesnapVaultedShopper($vaultedShopperId: Int!) {
      getBluesnapVaultedShopper(vaultedShopperId: $vaultedShopperId) {
        firstName
        lastName
        vaultedShopperId
        paymentSources {
          ecpDetails {
            billingContactInfo {
              firstName
              lastName
              zip
            }
            ecp {
              accountType
              publicAccountNumber
              publicRoutingNumber
            }
          }
          creditCardInfo {
            billingContactInfo {
              firstName
              lastName
              zip
            }
            processingInfo {
              avsResponseCodeAddress
              cvvResponseCode
              avsResponseCodeName
              avsResponseCodeZip
            }
            creditCard {
              cardLastFourDigits
              cardType
              cardCategory
              expirationYear
              cardFingerprint
              cardSubType
              expirationMonth
            }
          }
        }
        lastPaymentInfo {
          paymentMethod
          creditCard {
            cardLastFourDigits
            cardType
            cardCategory
            expirationYear
            cardFingerprint
            cardSubType
            expirationMonth
          }
        }
      }
    }
  `,
};

Bluesnap.mutations = {
  createPaymentToken: gql`
    mutation CreateBluesnapPaymentToken {
      createBluesnapPaymentToken
    }
  `,
  refundTransaction: gql`
    mutation RefundTransaction($transactionId: String) {
      refundTransaction(transactionId: $transactionId)
    }
  `,
  deleteAch: gql`
    mutation DeleteAch($input: DeleteAchInput!) {
      deleteAch(input: $input)
    }
  `,
  deleteCreditCard: gql`
    mutation DeleteCreditCard($input: DeleteCreditCardInput!) {
      deleteCreditCard(input: $input)
    }
  `,
  createTransaction: gql`
    mutation CreateBluesnapTransaction(
      $input: CreateBluesnapTransactionInput!
    ) {
      createBluesnapTransaction(input: $input) {
        amount
        transactionId
        currency
        cardTransactionType
        transactionPromotionId

        processingInfo {
          avsResponseCodeAddress
          cvvResponseCode
          avsResponseCodeName
          avsResponseCodeZip
          processingStatus
        }
      }
    }
  `,
};

const Bid = {};

Bid.mutations = {
  update: gql`
    mutation UpdateBid($input: UpdateBidInput!) {
      updateBid(input: $input) {
        ...BidPublic
      }
    }
    ${Fragments.Bid.public}
  `,
  counter: gql`
    mutation CounterBid($input: CounterBidInput!) {
      counterBid(input: $input) {
        ...BidPublic
      }
    }
    ${Fragments.Bid.public}
  `,
  updateBidStatus: gql`
    mutation UpdateBidStatus($input: UpdateBidStatusInput!) {
      updateBidStatus(input: $input) {
        ...BidPublic
      }
    }
    ${Fragments.Bid.public}
  `,
  createRaw: gql`
    mutation CreateBid($input: CreateBidInput!) {
      createBid(input: $input) {
        ...BidPublic
      }
    }
    ${Fragments.Bid.public}
  `,
  create: gql`
    mutation CreateNewBid($input: CreateNewBidInput!) {
      createNewBid(input: $input) {
        ...BidPublic
      }
    }
    ${Fragments.Bid.public}
  `,
};

Bid.subscriptions = {
  onCreate: gql`
    subscription OnMyCreateBid {
      onMyCreateBid {
        id
      }
    }
  `,
  onUpdate: gql`
    subscription OnMyCreateBid {
      onMyCreateBid {
        id
      }
    }
  `,
};

Bid.queries = {
  get: gql`
    query GetBid($id: ID!) {
      getBid(id: $id) {
        ...BidPublic
        listing {
          ...ListingPublic
        }
      }
    }
    ${Fragments.Bid.public}
    ${Fragments.Listing.public}
  `,
  listByListingId: gql`
    query BidsByListingId(
      $bidListingId: ID
      $sortDirection: ModelSortDirection
      $offer: ModelIntKeyConditionInput
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
    ) {
      bidsByListingId(
        bidListingId: $bidListingId
        sortDirection: $sortDirection
        offer: $offer
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
        }
        nextToken
      }
    }
    ${Fragments.Bid.public}
  `,
  listByBidderIdWithListingAndTicket: gql`
    query BidsByBidderId(
      $bidBidderId: ID
      $statusUpdatedAt: ModelBidByBidderIdStatusUpdatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
      $bidsFilter: ModelBidFilterInput
      $bidsLimit: Int
      $bidsNextToken: String
      $bidsSortDirection: ModelSortDirection
    ) {
      bidsByBidderId(
        bidBidderId: $bidBidderId
        statusUpdatedAt: $statusUpdatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
          listing {
            ...ListingPublic
            bids(
              filter: $bidsFilter
              limit: $bidsLimit
              nextToken: $bidsNextToken
              sortDirection: $bidsSortDirection
            ) {
              items {
                ...BidPublic
              }
              nextToken
            }
            ticket {
              ...TicketPublic
            }
          }
        }
        nextToken
      }
    }
    ${Fragments.Listing.public}
    ${Fragments.Bid.public}
    ${Fragments.Ticket.public}
  `,
  listByBidderId: gql`
    query BidsByBidderId(
      $bidBidderId: ID
      $statusUpdatedAt: ModelBidByBidderIdStatusUpdatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
    ) {
      bidsByBidderId(
        bidBidderId: $bidBidderId
        statusUpdatedAt: $statusUpdatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Fragments.Bid.public}
    ${Fragments.Listing.public}
  `,
  listByListingIdAndBidderIdAndUpdatedAt: gql`
    query ListBidsByListingIdAndBidderIdAndUpdatedAt(
      $bidListingId: ID
      $bidBidderIdUpdatedAt: ModelBidByListingIdAndBidderIdAndUpdatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listBidsByListingIdAndBidderIdAndUpdatedAt(
        bidListingId: $bidListingId
        bidBidderIdUpdatedAt: $bidBidderIdUpdatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
        }
        nextToken
      }
    }
    ${Fragments.Bid.public}
  `,
};

const Transaction = {};

Transaction.mutations = {
  update: gql`
    mutation UpdateTransaction($input: UpdateTransactionInput!) {
      updateTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Fragments.Transaction.public}
  `,
  create: gql`
    mutation CreateTransaction($input: CreateTransactionInput!) {
      createTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Fragments.Transaction.public}
  `,
  delete: gql`
    mutation DeleteTransaction($input: DeleteTransactionInput!) {
      deleteTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Fragments.Transaction.public}
  `,
};

Transaction.queries = {
  listByUserIdAndType: gql`
    query TransactionsByUserIdAndType(
      $transactionUserId: ID!
      $type: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByUserIdAndType(
        transactionUserId: $transactionUserId
        type: $type
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Fragments.Transaction.public}
  `,
  listByPromotionIdUserId: gql`
    query ListTransactionsByPromotionIdUserId(
      $transactionPromotionId: ID
      $transactionUserId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTransactionsByPromotionIdUserId(
        transactionPromotionId: $transactionPromotionId
        transactionUserId: $transactionUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Fragments.Transaction.public}
  `,
  get: gql`
    query GetTransaction($id: ID!) {
      getTransaction(id: $id) {
        ...TransactionPublic
        user {
          ...UserPublic
          ...UserPrivate
        }
      }
    }
    ${Fragments.Transaction.public}
    ${Fragments.User.public}
    ${Fragments.User.private}
  `,
  list: gql`
    query ListTransactions(
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TransactionPublic
        }
      }
    }
    ${Fragments.Transaction.public}
  `,
  listByTransactionIdUserId: gql`
    query TransactionsByTransactionIdUserId(
      $transactionId: String
      $transactionUserId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByTransactionIdUserId(
        transactionId: $transactionId
        transactionUserId: $transactionUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Fragments.Transaction.public}
  `,
  listByStatusUpdatedAt: gql`
    query TransactionsByStatusUpdatedAt(
      $status: TransactionStatus!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByStatusUpdatedAt(
        status: $status
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Fragments.Transaction.public}
  `,
  listByUserId: gql`
    query TransactionsByUserId(
      $transactionUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByUserId(
        transactionUserId: $transactionUserId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Fragments.Transaction.public}
  `,
};

const Activity = {};

Activity.mutations = {
  update: gql`
    mutation UpdateActivity($input: UpdateActivityInput!) {
      updateActivity(input: $input) {
        ...ActivityPublic
      }
    }
    ${Fragments.Activity.public}
  `,
  create: gql`
    mutation CreateActivity($input: CreateActivityInput!) {
      createActivity(input: $input) {
        ...ActivityPublic
      }
    }
    ${Fragments.Activity.public}
  `,
};

Activity.queries = {
  list: gql`
    query ListActivitys(
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Fragments.Activity.public}
  `,
  listByGlobalEligible: gql`
    query ListActivitiesByGlobalEligible(
      $globalEligible: StringBoolean
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByGlobalEligible(
        globalEligible: $globalEligible
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Fragments.Activity.public}
  `,
  listForNotificationCenter: gql`
    query ListActivitiesForNotificationCenter(
      $userId: ID
      $notificationCenterEligibleTypeCreatedAt: ModelActivityForNotificationCenterCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesForNotificationCenter(
        userId: $userId
        notificationCenterEligibleTypeCreatedAt: $notificationCenterEligibleTypeCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Fragments.Activity.public}
  `,
  listByUserIdAndCreatedAt: gql`
    query ListActivitiesByUserIdAndCreatedAt(
      $userId: ID
      $notificationCenterEligibleCreatedAt: ModelActivityByUserIdAndCreatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByUserIdAndCreatedAt(
        userId: $userId
        notificationCenterEligibleCreatedAt: $notificationCenterEligibleCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Fragments.Activity.public}
  `,
  listByModelAndModelId: gql`
    query ActivitiesByModelAndModelId(
      $model: String
      $modelId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      activitiesByModelAndModelId(
        model: $model
        modelId: $modelId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Fragments.Activity.public}
  `,
  get: gql`
    query GetActivity($id: ID!) {
      getActivity(id: $id) {
        ...ActivityPublic
      }
    }
    ${Fragments.Activity.public}
  `,
};

const Promotion = {};

Promotion.mutations = {
  update: gql`
    mutation UpdatePromotion($input: UpdatePromotionInput!) {
      updatePromotion(input: $input) {
        ...PromotionPublic
      }
    }
    ${Fragments.Promotion.public}
  `,
  create: gql`
    mutation CreatePromotion($input: CreatePromotionInput!) {
      createPromotion(input: $input) {
        ...PromotionPublic
      }
    }
    ${Fragments.Promotion.public}
  `,
};

Promotion.queries = {
  getByCode: gql`
    query GetPromotionByCode(
      $code: String
      $sortDirection: ModelSortDirection
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getPromotionByCode(
        code: $code
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...PromotionPublic
        }
        nextToken
      }
    }
    ${Fragments.Promotion.public}
  `,
  listByCampaignId: gql`
    query ListPromotionsByCampaignId(
      $promotionCampaignId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromotionsByCampaignId(
        promotionCampaignId: $promotionCampaignId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...PromotionPublic
        }
      }
    }
    ${Fragments.Promotion.public}
  `,
  list: gql`
    query ListPromotions(
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...PromotionPublic
        }
      }
    }
    ${Fragments.Promotion.public}
  `,
  get: gql`
    query GetPromotion($id: ID!) {
      getPromotion(id: $id) {
        ...PromotionPublic
      }
    }
    ${Fragments.Promotion.public}
  `,
};

const Campaign = {};

Campaign.mutations = {
  update: gql`
    mutation UpdateCampaign($input: UpdateCampaignInput!) {
      updateCampaign(input: $input) {
        ...CampaignPublic
      }
    }
    ${Fragments.Campaign.public}
  `,
  create: gql`
    mutation CreateCampaign($input: CreateCampaignInput!) {
      createCampaign(input: $input) {
        ...CampaignPublic
      }
    }
    ${Fragments.Campaign.public}
  `,
};

Campaign.queries = {
  list: gql`
    query ListCampaigns(
      $filter: ModelCampaignFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...CampaignPublic
        }
      }
    }
    ${Fragments.Campaign.public}
  `,
  get: gql`
    query GetCampaign($id: ID!) {
      getCampaign(id: $id) {
        ...CampaignPublic
      }
    }
    ${Fragments.Campaign.public}
  `,
};

const Referral = {};

Referral.mutations = {
  update: gql`
    mutation UpdateReferral($input: UpdateReferralInput!) {
      updateReferral(input: $input) {
        ...ReferralPublic
      }
    }
    ${Fragments.Referral.public}
  `,
  create: gql`
    mutation CreateReferral($input: CreateReferralInput!) {
      createReferral(input: $input) {
        ...ReferralPublic
      }
    }
    ${Fragments.Referral.public}
  `,
};

Referral.queries = {
  listByCodeAndReferreeId: gql`
    query ListReferralsByCodeAndReferreeId(
      $code: String
      $referreeId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferralsByCodeAndReferreeId(
        code: $code
        referreeId: $referreeId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Fragments.Referral.public}
  `,
  listByReferrerId: gql`
    query ListReferralsByReferrerId(
      $referrerId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferralsByReferrerId(
        referrerId: $referrerId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Fragments.Referral.public}
  `,
  list: gql`
    query ListReferrals(
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Fragments.Referral.public}
  `,
  get: gql`
    query GetReferral($id: ID!) {
      getReferral(id: $id) {
        ...ReferralPublic
      }
    }
    ${Fragments.Referral.public}
  `,
};

const ConversionTemplate = {};

ConversionTemplate.mutations = {
  update: gql`
    mutation UpdateConversionTemplate($input: UpdateConversionTemplateInput!) {
      updateConversionTemplate(input: $input) {
        ...ConversionTemplatePublic
      }
    }
    ${Fragments.ConversionTemplate.public}
  `,
  create: gql`
    mutation CreateConversionTemplate($input: CreateConversionTemplateInput!) {
      createConversionTemplate(input: $input) {
        ...ConversionTemplatePublic
      }
    }
    ${Fragments.ConversionTemplate.public}
  `,
};

ConversionTemplate.queries = {
  listTemplatesByBonusCode: gql`
    query ListConversionTemplatesByBonusCode(
      $bonusCode: String
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelConversionTemplateFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listConversionTemplatesByBonusCode(
        bonusCode: $bonusCode
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ConversionTemplatePublic
        }
      }
    }
    ${Fragments.ConversionTemplate.public}
  `,
  list: gql`
    query ListConversionTemplates(
      $filter: ModelConversionTemplateFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listConversionTemplates(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ConversionTemplatePublic
        }
      }
    }
    ${Fragments.ConversionTemplate.public}
  `,
  get: gql`
    query GetConversionTemplate($id: ID!) {
      getConversionTemplate(id: $id) {
        ...ConversionTemplatePublic
      }
    }
    ${Fragments.ConversionTemplate.public}
  `,
};

const Redemption = {};

Redemption.mutations = {
  update: gql`
    mutation UpdateRedemption($input: UpdateRedemptionInput!) {
      updateRedemption(input: $input) {
        ...RedemptionPublic
      }
    }
    ${Fragments.Redemption.public}
  `,
  create: gql`
    mutation CreateRedemption($input: CreateRedemptionInput!) {
      createRedemption(input: $input) {
        ...RedemptionPublic
      }
    }
    ${Fragments.Redemption.public}
  `,
};

Redemption.queries = {
  getByUserIdAndPromotionId: gql`
    query GetRedemptionByUserIdAndPromotionId(
      $redemptionUserId: ID!
      $redemptionPromotionId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getRedemptionByUserIdAndPromotionId(
        redemptionUserId: $redemptionUserId
        redemptionPromotionId: $redemptionPromotionId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Fragments.Redemption.public}
  `,
  listByPromotionIdCreatedAt: gql`
    query ListRedemptionsByPromotionIdCreatedAt(
      $redemptionPromotionId: ID
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedemptionsByPromotionIdCreatedAt(
        redemptionPromotionId: $redemptionPromotionId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Fragments.Redemption.public}
  `,
  getByUserIdAndCampaignIdAndPromotionId: gql`
    query GetRedemptionByUserIdAndCampaignIdAndPromotionId(
      $redemptionUserId: ID
      $redemptionPromotionIdRedemptionCampaignId: ModelRedemptionByUserIdAndCampaignIdIdAndPromotionIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getRedemptionByUserIdAndCampaignIdAndPromotionId(
        redemptionUserId: $redemptionUserId
        redemptionPromotionIdRedemptionCampaignId: $redemptionPromotionIdRedemptionCampaignId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Fragments.Redemption.public}
  `,
  list: gql`
    query ListRedemptions(
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedemptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...RedemptionPublic
        }
      }
    }
    ${Fragments.Redemption.public}
  `,
  get: gql`
    query GetRedemption($id: ID!) {
      getRedemption(id: $id) {
        ...RedemptionPublic
      }
    }
    ${Fragments.Redemption.public}
  `,
};

const SportsbookLocale = {};

SportsbookLocale.mutations = {
  update: gql`
    mutation UpdateSportsbookLocale($input: UpdateSportsbookLocaleInput!) {
      updateSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  create: gql`
    mutation CreateSportsbookLocale($input: CreateSportsbookLocaleInput!) {
      createSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  delete: gql`
    mutation DeleteSportsbookLocale($input: DeleteSportsbookLocaleInput!) {
      deleteSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
};

SportsbookLocale.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateSportsbookLocale {
      onUpdateSportsbookLocale {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  onCreate: gql`
    subscription OnCreateSportsbookLocale {
      onCreateSportsbookLocale {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
};

SportsbookLocale.queries = {
  get: gql`
    query GetSportsbookLocale($id: ID!) {
      getSportsbookLocale(id: $id) {
        ...SportsbookLocalePublic
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  listBySportsbookId: gql`
    query ListSportsbookLocalesBySportsbookId(
      $sportbookId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listSportsbookLocalesBySportsbookId(
        sportbookId: $sportbookId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  listByLocaleId: gql`
    query ListSportsbookLocalesByLocaleId(
      $localeId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listSportsbookLocalesByLocaleId(
        localeId: $localeId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
  list: gql`
    query ListSportsbookLocales(
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSportsbookLocales(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${Fragments.SportsbookLocale.public}
  `,
};

const TicketView = {};

TicketView.mutations = {
  update: gql`
    mutation UpdateTicketView($input: UpdateTicketViewInput!) {
      updateTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
  create: gql`
    mutation CreateTicketView($input: CreateTicketViewInput!) {
      createTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
  delete: gql`
    mutation DeleteTicketView($input: DeleteTicketViewInput!) {
      deleteTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
};

TicketView.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateTicketView {
      onUpdateTicketView {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
  onCreate: gql`
    subscription OnCreateTicketView {
      onCreateTicketView {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
};

TicketView.queries = {
  get: gql`
    query GetTicketView($id: ID!) {
      getTicketView(id: $id) {
        ...TicketViewPublic
      }
    }
    ${Fragments.TicketView.public}
  `,
  list: gql`
    query ListTicketViews(
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${Fragments.TicketView.public}
  `,
  listByTicketId: gql`
    query ListTicketViewsByTicketId(
      $ticketId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByTicketId(
        ticketId: $ticketId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${Fragments.TicketView.public}
  `,
  listByUserIdAndTicketId: gql`
    query ListTicketViewsByUserIdAndTicketId(
      $ticketViewUserId: ID
      $ticketIdCreatedAt: ModelTicketViewByUserIdAndTicketIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByUserIdAndTicketId(
        ticketViewUserId: $ticketViewUserId
        ticketIdCreatedAt: $ticketIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${Fragments.TicketView.public}
  `,
  listByUserId: gql`
    query ListTicketViewsByUserId(
      $userId: ID
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByUserId(
        userId: $userId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${Fragments.TicketView.public}
  `,
};

const Search = {
  mutations: {
    update: gql`
      mutation UpdateSearch($input: UpdateSearchInput!) {
        updateSearch(input: $input) {
          ...SearchPublic
        }
      }
      ${Fragments.Search.public}
    `,
    create: gql`
      mutation CreateSearch($input: CreateSearchInput!) {
        createSearch(input: $input) {
          ...SearchPublic
        }
      }
      ${Fragments.Search.public}
    `,
    delete: gql`
      mutation DeleteSearch($input: DeleteSearchInput!) {
        deleteSearch(input: $input) {
          ...SearchPublic
        }
      }
      ${Fragments.Search.public}
    `,
  },
  queries: {
    listByUserId: gql`
      query ListSearchesByUserId(
        $userId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSearchFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSearchesByUserId(
          userId: $userId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...SearchPublic
          }
          nextToken
        }
      }
      ${Fragments.Search.public}
    `,
    get: gql`
      query GetSearch($id: ID!) {
        getSearch(id: $id) {
          ...SearchPublic
        }
      }
      ${Fragments.Search.public}
    `,
    list: gql`
      query ListSearchs(
        $filter: ModelSearchFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            ...SearchPublic
          }
          nextToken
        }
      }
      ${Fragments.Search.public}
    `,
  },
};

export {
  Fragments,
  Campaign,
  Redemption,
  Promotion,
  User,
  Listing,
  Ticket,
  Bid,
  Search,
  Transaction,
  Activity,
  Locale,
  Sport,
  Bluesnap,
  Cognito,
  Interchecks,
  Event,
  Group,
  Option,
  OptionGroup,
  Device,
  Bovado,
  OptionEventOdds,
  Sportsbook,
  SportsbookLocale,
  TicketView,
  Watching,
  Referral,
  ConversionTemplate,
  Notifications,
  Redirect,
  Link,
  ExchangeTransaction,
};
