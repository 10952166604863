import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useStyles from "Hooks/useStyles";
import { useLayoutEffect, useContext, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Platform } from "react-native";
import { formatPennies } from "shared/Util/money";
import Bid from "shared/api/Bid";
import Listing from "shared/api/Listing";
import User from "shared/api/User";
import * as yup from "yup";
import moment from "moment";

import ListingBidBuyFormStyles from "./ListingBidBuyFormStyles";
import ListingBidBuyFormView from "./ListingBidBuyFormView";
import requestReview from "Util/requestReview";

export default ({
  listing,
  ticket,
  minBid,
  defaultAmount,
  onCancelPress,
  onSubmit: passedOnSubmit,
  hasPendingBid,
  fundsAvailable,
  mode,
}) => {
  const askingPrice = listing?.askingPrice;
  const schema = useMemo(
    () =>
      yup.object().shape({
        ...(!!ticket.inPropswapsPossession
          ? {}
          : { understand: yup.boolean().oneOf([true]) }),
        terms: yup.boolean().oneOf([true]),
        amount: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : parseFloat(value)))
          .required("Can't be blank")
          .test(
            "min-bid",
            `Can't be more than the asking price of $${formatPennies(
              askingPrice
            )}`,
            value => value < askingPrice / 100.0 + 0.01
          )
          .test(
            "asking-price",
            `Must be $${formatPennies(minBid || 100)} or more`,
            value =>
              value * 100 >= askingPrice ||
              value > (minBid || 100) / 100.0 - 0.01
          )
          // .moreThan(
          //   minBid / 100.0 - 0.01,

          // )
          .lessThan(
            askingPrice / 100.0 + 0.01,
            `Can't be more than the asking price of $${formatPennies(
              askingPrice
            )}`
          )
          .lessThan(
            fundsAvailable / 100.0 + 0.01,
            `You do not have enough funds in your Balance`
          ),
        expiresAt: yup
          .string()
          .optional()
          .nullable()
          .test(
            "min-expires-at",
            `Bid Expiration must be at least 2 minutes ahead`,
            value =>
              !value ||
              (moment(value).isValid() &&
                moment().toDate() <
                  moment(value).subtract("2", "minutes").toDate())
          ),
      }),
    [ticket.inPropswapsPossession, askingPrice, minBid, fundsAvailable]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      amount: defaultAmount / 100.0,
    },
  });

  const amount = watch("amount", "");
  console.log("amount", amount);
  const expiresAt = watch("expiresAt", "");
  const expiresAtFormattedString = !expiresAt
    ? undefined
    : moment(expiresAt).toDate().toISOString();
  const amountInPennies = !!amount ? Math.round(amount * 100.0) : defaultAmount;
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: ListingBidBuyFormStyles });

  const [buyListing] = useMutation(Listing.mutations.buy, {
    variables: {
      input: {
        id: listing?.id,
        boughtFrom: Platform.OS.toUpperCase(),
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.get,
        variables: { id: listing?.id },
      },
    ],
  });

  const [createBid] = useMutation(Bid.mutations.create, {
    variables: {
      input: {
        offer: amountInPennies,
        expiresAt: expiresAtFormattedString,
        bidListingId: listing.id,
        bidBidderId: currentUser?.id,
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser?.id,
          withPrivate: true,
        },
      },
    ],
  });

  const handleSubmitError = useCallback(
    error =>
      setError("amount", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  // const shouldRequestReview = !!currentUser && !currentUser?.promptedToRate;
  // const [setPromptedToRate] = useMutation(User.mutations.update, {
  //   variables: {
  //     input: {
  //       id: currentUser?.id,
  //       promptedToRate: true
  //     }
  //   }
  // })

  const onSubmit = useCallback(
    ({ amount }) => {
      const amountInPennies = parseInt(parseFloat(amount) * 100.0);
      return (
        amountInPennies === askingPrice
          ? buyListing()
          : // .then(setPromptedToRate)
            createBid()
      )
        .then(passedOnSubmit)
        .then(requestReview)
        .catch(handleSubmitError);
    },
    [askingPrice, buyListing, createBid, passedOnSubmit, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);

  useLayoutEffect(() => {
    trigger();
  }, [trigger]);

  console.log("listing", listing.askingPrice, amountInPennies);

  return (
    <ListingBidBuyFormView
      listing={listing}
      ticket={ticket}
      defaultAmount={defaultAmount}
      doSubmit={doSubmit}
      onCancelPress={onCancelPress}
      hasPendingBid={hasPendingBid}
      styles={styles}
      theme={theme}
      control={control}
      isValid={isValid}
      isSubmitting={isSubmitting}
      amountInPennies={amountInPennies}
      errors={errors}
      mode={mode}
    />
  );
};
