import { useContext, useCallback, useState } from "react";

import PhotoUploadView from "./PhotoUploadView";

import { CurrentUserContext } from "Contexts/CurrentUser";
import { AvatarContext } from "Contexts/Avatar";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "./PhotoUploadStyles";

export default () => {
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: PhotoUploadStyles });
  const [wantsUpload, setWantsUpload] = useState(false);
  const { setLocalUri } = useContext(AvatarContext);

  const handleImageChange = useCallback(
    image => [setLocalUri(image.uri), setWantsUpload(false)],
    [setLocalUri]
  );

  return (
    <PhotoUploadView
      wantsUpload={wantsUpload}
      setWantsUpload={setWantsUpload}
      theme={theme}
      onImageChange={handleImageChange}
      currentUser={currentUser}
      styles={styles}
    />
  );
};
