import { Alert } from "react-native";
import { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import Watching from "shared/api/Watching";
import { CurrentUserContext } from "Contexts/CurrentUser";
import WatchingIconView from "./WatchingIconView";

export default ({ theme, isFeatured, listing }) => {
  const currentUser = useContext(CurrentUserContext);

  const listingId = listing?.id;
  const watchingId = (currentUser?.watchings ?? []).find(
    watching => watching.listingId === listingId
  )?.id;
  const isWatching = !!watchingId;

  const [createWatching] = useMutation(Watching.mutations.create, {
    refetchQueries: [
      {
        query: Watching.queries.listByUserId,
        variables: {
          userId: currentUser?.id,
          limit: 200,
          sortDirection: "DESC",
        },
      },
    ],
    variables: {
      input: {
        listingId: listing?.id,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createWatching: {
        listingId: listing?.id,
        userId: currentUser?.id,
        __typename: "Watching",
      },
    },
  });

  const [deleteWatching] = useMutation(Watching.mutations.delete, {
    refetchQueries: [
      {
        query: Watching.queries.listByUserId,
        variables: {
          userId: currentUser?.id,
          limit: 200,
          sortDirection: "DESC",
        },
      },
    ],
    variables: {
      input: {
        id: watchingId,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteWatching: {
        listingId: listing?.id,
        userId: currentUser?.id,
        __typename: "Watching",
      },
    },
  });

  const handlePress = useCallback(
    () =>
      !currentUser
        ? Alert.alert("You are not logged in")
        : !!isWatching
        ? deleteWatching()
        : createWatching(),
    [currentUser, isWatching, deleteWatching, createWatching]
  );

  return (
    <WatchingIconView
      theme={theme}
      isFeatured={isFeatured}
      onPress={handlePress}
      isBookedMarked={isWatching}
    />
  );
};
