import CircleIndicator from "Components/CircleIndicator";
import DataList from "Components/DataList";
import useStyles from "Hooks/useStyles";
import openUrl from "Util/openUrl";
import gql from "graphql-tag";
import { useMemo, useCallback, useState, useEffect, useRef } from "react";
import { Platform, View, useWindowDimensions } from "react-native";
import { Button } from "react-native-paper";

import AdItem from "../Item";

const query = gql`
  query ListAdsByPosition(
    $active: StringBoolean!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByPosition(
      active: $active
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lightModeImageUrl
        darkModeImageUrl
        url
        position
        active
      }
      nextToken
    }
  }
`;

export default ({ onFetchItems, adCount }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const windowWidth = useWindowDimensions().width;
  const maxWidth =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const dataList = useRef();
  const [itemCount, setItemCount] = useState(0);
  const [scrolledIndex, setScrolledIndex] = useState(0);
  // const imageWidth = Math.min(width, Platform.OS === "web" ? 601 : 50000)
  const { theme } = useStyles();
  // const onPress = openUrl.bind(null, "https://propswap.ac-page.com/refer-a-friend-seller-promo-102021?test=true")
  // const { onPress, ...props } = useLinkProps({ to: "/account/wallet/deposit/card" });

  const dataListQuery = useMemo(
    () => ({
      dataKey: "listAdsByPosition",
      query,
      variables: {
        active: "YES",
      },
    }),
    []
  );

  const handleItemPress = useCallback(item => () => openUrl(item.url), []);

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          width: maxWidth, // - theme.spacing.xl * 2,
        }}
      >
        <View
          style={{
            ...Platform.select({
              web: {
                marginRight: theme.spacing.xl * 2,
              },
              default: {
                marginRight: theme.spacing.xl,
                marginLeft: theme.spacing.xl,
              },
            }),
          }}
        >
          <AdItem
            adCount={adCount}
            ad={item}
            theme={theme}
            onPress={handleItemPress}
          />
        </View>
      </View>
    ),
    [maxWidth, theme, adCount, handleItemPress]
  );

  const handleFetchItems = useCallback(
    items => [onFetchItems(items), setItemCount(items?.length ?? 0)],
    [onFetchItems]
  );

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems, changed }) =>
      setScrolledIndex(viewableItems?.[0]?.index ?? 0),
    []
  );

  useEffect(() => {
    !!dataList.current &&
      currentIndex !== null &&
      dataList.current.scrollToIndex({ animated: true, index: currentIndex });
  }, [currentIndex, dataList]);

  return (
    <>
      {itemCount > 1 && Platform.OS === "web" && (
        <View
          style={{
            marginTop: -1 * theme.spacing.lg * 2,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={currentIndex <= 0}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex - 1)}
          >
            Prev
          </Button>
          <Button
            disabled={currentIndex >= itemCount - 1}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex + 1)}
          >
            Next
          </Button>
        </View>
      )}
      {itemCount > 1 && Platform.OS !== "web" && (
        <CircleIndicator
          itemCount={itemCount}
          scrolledIndex={scrolledIndex}
          onItemPress={setCurrentIndex}
        />
      )}
      <DataList
        ref={dataList}
        query={dataListQuery}
        renderItem={dataListRenderItem}
        horizontal
        pagingEnabled={Platform.OS !== "web"}
        showsHorizontalScrollIndicator={false}
        onEndReached={() => null}
        onFetchItems={handleFetchItems}
        onViewableItemsChanged={handleViewableItemsChanged}
      />
    </>
  );
};
