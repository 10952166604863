import gql from "graphql-tag";
import environment from "environment";

const Activity = {};

Activity.fragments = {
  public: gql`
    fragment ActivityPublic on Activity {
      id
      createdAt
      updatedAt
      userId
      subjectId

      model
      modelId
      title
      body
      type
      data

      notificationCenterEligible
      globalEligible

      subject {
        id
        displayName
        status
        profilePicUri
      }

      user {
        id
        displayName
        status
        profilePicUri
      }
    }
  `,
};

Activity.mutations = {
  update: gql`
    mutation UpdateActivity($input: UpdateActivityInput!) {
      updateActivity(input: $input) {
        ...ActivityPublic
      }
    }
    ${Activity.fragments.public}
  `,
  create: gql`
    mutation CreateActivity($input: CreateActivityInput!) {
      createActivity(input: $input) {
        ...ActivityPublic
      }
    }
    ${Activity.fragments.public}
  `,
};

Activity.queries = {
  list: gql`
    query ListActivitys(
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listByTypeAndUserId: gql`
    query ListActivitiesByTypeAndUserId(
      $type: ${environment.use_v2 ? "String!" : "ActivityType"}
      $userIdCreatedAt: ModelActivityByTypeAndUserIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByTypeAndUserId(
        type: $type
        userIdCreatedAt: $userIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listByGlobalEligible: gql`
    query ListActivitiesByGlobalEligible(
      $globalEligible: ${environment.use_v2 ? "String!" : "StringBoolean"}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByGlobalEligible(
        globalEligible: $globalEligible
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
          user {
            id
            displayName
            status
          }
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listForNotificationCenter: gql`
    query ListActivitiesForNotificationCenter(
      $userId: ID
      $notificationCenterEligibleTypeCreatedAt: ModelActivityForNotificationCenterCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesForNotificationCenter(
        userId: $userId
        notificationCenterEligibleTypeCreatedAt: $notificationCenterEligibleTypeCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listByUserIdAndCreatedAt: gql`
    query ListActivitiesByUserIdAndCreatedAt(
      $userId: ID!
      $notificationCenterEligibleCreatedAt: ModelActivityByUserIdAndCreatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByUserIdAndCreatedAt(
        userId: $userId
        notificationCenterEligibleCreatedAt: $notificationCenterEligibleCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listByType: gql`
    query ListActivitiesByType(
      $type: ${environment.use_v2 ? "String!" : "ActivityType"}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listActivitiesByType(
        type: $type
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  listByModelAndModelId: gql`
    query ActivitiesByModelAndModelId(
      $model: ${environment.use_v2 ? "String!" : "String"}
      $modelId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelActivityFilterInput
      $limit: Int
      $nextToken: String
    ) {
      activitiesByModelAndModelId(
        model: $model
        modelId: $modelId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ActivityPublic
        }
        nextToken
      }
    }
    ${Activity.fragments.public}
  `,
  get: gql`
    query GetActivity($id: ID!) {
      getActivity(id: $id) {
        ...ActivityPublic
      }
    }
    ${Activity.fragments.public}
  `,
};

export default Activity;
