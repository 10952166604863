import { valueMeter } from "shared/Config";
import useStyles from "Hooks/useStyles";
import ListingValueMeterStyles from "./ListingValueMeterStyles";
import ListingValueMeterView from "./ListingValueMeterView";

export default ({ valueRating, disableValues }) => {
  const { theme, styles } = useStyles({ Styles: ListingValueMeterStyles });
  const showValueMeter =
    !disableValues && !!valueMeter[(valueRating || 0) - 1]?.label;

  return (
    <ListingValueMeterView
      showValueMeter={showValueMeter}
      valueRating={valueRating}
      theme={theme}
      styles={styles}
    />
  );
};
