import gql from "graphql-tag";
import environment from "environment";
import User from "./User";
// import Ticket from "./Ticket"

const Ticket = {};

const searchRawQuery = environment.use_v2
  ? `query SearchListingsRaw2(
      $filter: AWSJSON
      $sort: [SearchableListingRawSortInput]
      $limit: Int
      $nextToken: String
    ) {
      searchListingsRaw2(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }`
  : `query SearchListingsRaw(
      $filter: AWSJSON
      $sortJson: AWSJSON
      $sort: [SearchableListingRawSortInput]
      $sort2: SearchableListingRawSortInput
      $limit: Int
      $nextToken: String
    ) {
      searchListingsRaw(
        filter: $filter
        sort: $sort
        sort2: $sort2
        sortJson: $sortJson
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    `;

Ticket.fragments = {
  public: gql`
    fragment TicketPublic on Ticket {
      id
      createdAt
      updatedAt
      odds
      oddsCalculated
      photoUrl
      betType
      eventDescription
      subject
      winAmount
      betAmount
      collectAmount
      additionalInfo
      ticketId
      bookName
      isMobile
      creatorId
      source
      result
      inPropswapsPossession
      reviewed
      ticketUserId
      ticketSportId
      ticketOriginalOwnerId
      ticketLocaleId
      ticketSportsbookId
      ticketEventId
      ticketOptionId
      hasShipped
      disableValues
      event {
        id
        description
        active
        featuredAt
        createdAt
        updatedAt
        bovadoGroupId
        bovadoEventId
        fanDuelMarketId
        fanDuelEventId
        rebateMessage
        rebateActive
      }

      option {
        id
        name
        active
        createdAt
        bovadoParticipantId
        bovadoParticipantName
        fanDuelSelectionId
      }

      sportsbook {
        id
        name
      }

      sport {
        id
        abbrev
        name
      }

      locale {
        id
        name
        state
        mailingAddress
      }

      views
    }
  `,
};

const Listing = {};

Listing.fragments = {
  public: gql`
    fragment ListingPublic on Listing {
      id
      createdAt
      updatedAt
      expiresAt
      soldAt
      bidCount
      salesPrice
      askingPrice
      minimumBidPrice
      newOdds
      newOddsCalculated
      status
      ratingOverride
      featuredAt
      listingTicketId
      listingBuyerId
      listingSellerId
      searchString
      score
      recentlySold
      postedFrom
      boughtFrom
      valueRating
      rebateStatus
      watchingsCount
      notes
      commissionWaiverApplied
      pendingBidsCount
      cachedBid {
        id
        createdAt
        updatedAt
        offer
        counter
        status
        bidListingId
        bidBidderId
        expiresAt
        bidder {
          id
          displayName
          profilePicUri
          status
        }
      }
      cachedTicket {
        id
        createdAt
        updatedAt
        odds
        oddsCalculated
        photoUrl
        betType
        eventDescription
        subject
        winAmount
        betAmount
        collectAmount
        additionalInfo
        ticketId
        bookName
        isMobile
        creatorId
        source
        result
        inPropswapsPossession
        reviewed
        ticketUserId
        ticketSportId
        ticketOriginalOwnerId
        ticketLocaleId
        ticketSportsbookId
        ticketEventId
        ticketOptionId
        hasShipped
        views
        providerTicketId
        providerId
        disableValues
        sport {
          id
          abbrev
          name
        }
        cachedBets {
          id
          eventId
          optionId
          eventDescription
          subject
        }
      }
    }
  `,
};

Listing.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateListingPublic {
      onUpdateListingPublic {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateListing {
      onCreateListing {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
};

Listing.queries = {
  listBySellerId: gql`
    query ListingsBySellerId(
      $listingSellerId: ID!
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsBySellerId(
        listingSellerId: $listingSellerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  listBySellerIdWithTicket: gql`
    query ListingsBySellerId(
      $listingSellerId: ID!
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsBySellerId(
        listingSellerId: $listingSellerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
    ${Ticket.fragments.public}
  `,
  listByBuyerIdWithTicket: gql`
    query ListingsByBuyerId(
      $listingBuyerId: ID!
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsByBuyerId(
        listingBuyerId: $listingBuyerId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          ticket {
            ...TicketPublic
          }
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
    ${Ticket.fragments.public}
  `,
  listRecommendedByUser: gql`
    query ListRecommendedListingsByUser(
      $userId: ID!
      $includeInactive: Boolean
    ) {
      listRecommendedListingsByUser(
        userId: $userId
        includeInactive: $includeInactive
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  listByTicketId: gql`
    query ListingsByTicketId(
      $listingTicketId: ID!
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsByTicketId(
        listingTicketId: $listingTicketId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  listByTicketIdWithSellerAndBuyer: gql`
    query ListingsByTicketIdWithSellerAndBuyer(
      $listingTicketId: ID!
      $status: ModelStringKeyConditionInput
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listingsByTicketId(
        listingTicketId: $listingTicketId
        status: $status
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          seller {
            displayName
          }
          buyer {
            displayName
          }
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  search: gql`
    query SearchListings(
      $filter: SearchableListingFilterInput
      $sort: ${
        environment.use_v2
          ? "[SearchableListingSortInput]"
          : "SearchableListingSortInput"
      }
      $limit: Int
      $nextToken: String
    ) {
      searchListings(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  listByStatusAndExpiresAt: gql`
    query ListListingByStatusAndExpiresAt(
      $status: ${environment.use_v2 ? "String!" : "ListingStatus"}
      $expiresAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listListingByStatusAndExpiresAt(
        status: $status
        expiresAt: $expiresAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
          items {
            ...ListingPublic
          }
      }
    }
    ${Listing.fragments.public}
  `,
  searchWithTicket: gql`
    query SearchListings(
      $filter: SearchableListingFilterInput
      $sort: ${
        environment.use_v2
          ? "[SearchableListingSortInput]"
          : "SearchableListingSortInput"
      }
      $limit: Int
      $nextToken: String
    ) {
      searchListings(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ListingPublic
          cachedTicket {
            id
            createdAt
            updatedAt
            odds
            oddsCalculated
            photoUrl
            betType
            eventDescription
            subject
            winAmount
            betAmount
            collectAmount
            additionalInfo
            ticketId
            bookName
            isMobile
            creatorId
            source
            result
            inPropswapsPossession
            reviewed
            ticketUserId
            ticketSportId
            ticketOriginalOwnerId
            ticketLocaleId
            ticketSportsbookId
            ticketEventId
            ticketOptionId
            hasShipped
            views
            providerTicketId
            providerId
            disableValues
            sport {
              id
              abbrev
              name
            }
          }
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
  searchRaw: gql`
    ${searchRawQuery}
    ${Listing.fragments.public}
  `,
  get: gql`
    query GetListing($id: ID!, $withSeller: Boolean = false) {
      getListing(id: $id) {
        ...ListingPublic
        seller @include(if: $withSeller) {
          ...UserPublic
        }
      }
    }
    ${Listing.fragments.public}
    ${User.fragments.public}
  `,
  list: gql`
    query ListListings(
      $filter: ModelListingFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ListingPublic
        }
        nextToken
      }
    }
    ${Listing.fragments.public}
  `,
};

Listing.mutations = {
  buy: gql`
    mutation BuyListing($input: BuyListingInput!) {
      buyListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  pause: gql`
    mutation PauseListing($input: UpdateListingStatusInput!) {
      pauseListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  reactivate: gql`
    mutation ReactiviateListing($input: UpdateListingStatusInput!) {
      reactivateListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  update: gql`
    mutation UpdateListing($input: UpdateListingInput!) {
      updateListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  createRaw: gql`
    mutation CreateListing($input: CreateListingInput!) {
      createListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  create2: gql`
    mutation CreateNewListing2($input: CreateNewListingInput2!) {
      createNewListing2(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
  create: gql`
    mutation CreateNewListing($input: CreateNewListingInput!) {
      createNewListing(input: $input) {
        ...ListingPublic
      }
    }
    ${Listing.fragments.public}
  `,
};

export default Listing;
