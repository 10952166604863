import { LineChart } from "react-native-chart-kit";
import { Platform } from "react-native";

export default ({ width, theme, style, labels, data }) => (
  <LineChart
    // hidePointsAtIndex={[0]}
    formatXLabel={value =>
      [...labels].reverse().indexOf(value) % 4 === 0 ? value : ""
    }
    formatYLabel={value => {
      value = value.toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(",");
      return value;
    }}
    withInnerLines={false}
    withOuterLines={false}
    getDotColor={(dataPoint, dataPointIndex) => "#FFFFFF"}
    data={{
      labels,
      datasets: [
        {
          data,
        },
      ],
    }}
    width={width} // from react-native
    height={220}
    yAxisLabel="$"
    fromZero
    // yAxisSuffix="k"
    yAxisInterval={1} // optional, defaults to 1
    // xAxisInterval={4}
    chartConfig={{
      propsForLabels: {
        fontSize: 10,
        fontWeight: "bold",
        fontFamily:
          Platform.OS === "web"
            ? "Helvetica"
            : Platform.OS === "android"
            ? "Roboto"
            : "System",
      },
      backgroundColor: theme.colors.surface,
      backgroundGradientFrom: theme.colors.surface,
      backgroundGradientTo: theme.colors.surface,
      decimalPlaces: 0, // optional, defaults to 2dp
      // color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      color: (opacity = 1) => theme.colors.secondary,
      labelColor: (opacity = 1) => `grey`,
      // labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      // style: {
      //   borderStyle: "dashed"
      // },
      propsForDots: {
        r: "6",
        strokeWidth: "2",
        stroke: "#ffa726",
      },
    }}
    bezier
    style={style}
  />
);
