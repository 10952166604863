import { Button, HelperText, Subheading, TextInput } from "react-native-paper";
import { TextInputMask } from "react-native-masked-text";

import { Platform, View } from "react-native";
import { Controller } from "react-hook-form";

export default ({
  styles,
  onSubmit,
  theme,
  control,
  currentUser,
  errors,
  action,
  isValid,
  isSubmitting,
}) => (
  <View style={{ flex: 1, paddingTop: theme.spacing.xl * 2 }}>
    <Subheading>
      As of January 1, 2022 the IRS now requires all customers who receive $600
      or more in a calendar year to provide their Social Security Number. This
      information will be used to prepare the IRS Form 1099-K at the end of the
      year.
    </Subheading>
    <View style={{ marginVertical: theme.spacing.xl }}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              autoFocus
              autoCorrect={false}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "username"
              }
              textContentType="none"
              label="Enter your Social Security Number"
              autoCapitalize="none"
              returnKeyLabel="Done"
              returnKeyType="done"
              error={!!errors.recipientSsn}
              onSubmitEditing={onSubmit}
              maxLength={11}
              mode="contained"
              onBlur={onBlur}
              onChangeText={value => onChange((value ?? "").replace(/\D/g, ""))}
              value={value}
              render={props => (
                <TextInputMask
                  {...props}
                  type="custom"
                  options={{
                    mask: "999-99-9999",
                  }}
                  getRawValue={(value, settings) => value.replace(/\D/g, "")}
                />
              )}
              // style={{
              //   backgroundColor: "white",
              // }}
            />
            <HelperText type="info" visible>
              Please note: PropSwap does not store your SSN.
            </HelperText>
            <HelperText type="error" visible={!!errors.recipientSsn}>
              {errors.recipientSsn?.message}
            </HelperText>
          </>
        )}
        name="recipientSsn"
        defaultValue=""
      />
    </View>

    <View style={{ flex: 1, justifyContent: "flex-end" }}>
      <Button
        loading={!!isSubmitting}
        disabled={!!isSubmitting || !isValid}
        mode="contained"
        onPress={onSubmit}
      >
        Submit
      </Button>
    </View>
  </View>
);
