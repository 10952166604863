import gql from "graphql-tag";
import Event from "./Event";
import Option from "./Option";

const OptionEventOdds = {};

OptionEventOdds.fragments = {
  public: gql`
    fragment OptionEventOddsPublic on OptionEventOdds {
      id
      userId
      eventId
      optionId

      createdAt
      updatedAt

      oddsFormated
      oddsCalculated

      option {
        ...OptionPublic
      }
      event {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
    ${Option.fragments.public}
  `,
};
OptionEventOdds.mutations = {
  update: gql`
    mutation UpdateOptionEventOdds($input: UpdateOptionEventOddsInput!) {
      updateOptionEventOdds(input: $input) {
        ...OptionEventOddsPublic
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  create: gql`
    mutation CreateOptionEventOdds($input: CreateOptionEventOddsInput!) {
      createOptionEventOdds(input: $input) {
        ...OptionEventOddsPublic
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
};

OptionEventOdds.queries = {
  get: gql`
    query GetOptionEventOddss($id: ID!) {
      getOptionEventOddss(id: $id) {
        ...OptionEventOddsPublic
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  list: gql`
    query ListOptionEventOddss(
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddss(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  listByEventId: gql`
    query ListOptionEventOddsByEventId(
      $eventId: ID!
      $oddsCalculated: ModelFloatKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventId(
        eventId: $eventId
        oddsCalculated: $oddsCalculated
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  listByEventIdAndCreatedAt: gql`
    query ListOptionEventOddsByEventIdAndCreatedAt(
      $eventId: ID!
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventIdAndCreatedAt(
        eventId: $eventId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  listByEventIdAndOptionId: gql`
    query ListOptionEventOddsByEventIdAndOptionId(
      $eventId: ID!
      $optionIdCreatedAt: ModelOptionEventOddsByEventIdAndOptionIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByEventIdAndOptionId(
        eventId: $eventId
        optionIdCreatedAt: $optionIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
  listByOptionId: gql`
    query ListOptionEventOddsByOptionId(
      $optionId: ID!
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionEventOddsFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionEventOddsByOptionId(
        optionId: $optionId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionEventOddsPublic
        }
        nextToken
      }
    }
    ${OptionEventOdds.fragments.public}
  `,
};

export default OptionEventOdds;
